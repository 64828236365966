<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/PageHeader.vue";
import appConfig from "../../../app.config";

import axios from 'axios';

import Navigator from "./_Navigator.vue";
import Result from "./_Result.vue";

export default {
  page: {
    title: "Search",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Search results",
      type: 'episodes',
      query: '',
      data: {
        search: {
          query: ''
        }
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    Result,
    Navigator,
  },
  created: function () {
  },
  mounted() {
    var query = this.$route.query.q;
    var tab = this.validateTab(this.$route.query.t);
    if(tab)
      this.type = tab;
    if(query)
      this.search(tab, query);
  },
  methods: {
    validateTab(tab) {
      if(tab === 'episodes' || tab === 'podcasts' || tab === 'posts' || tab === 'profiles')
        return tab;
      return 'episodes';
    },
    changeType() {
      this.data.search.query = this.$refs.searchInput.value;
    },
    search(tab, query) {
      if(this.$route.query.q && tab) {
        axios.post('/api/search/' + tab, { query: query }, { withCredentials: true })
          .then((response) => {
            this.data = response.data;
          })
          .catch(function () {
          });
      }
    },
    searchClick() {
      this.query = this.$refs.searchInput.value;
      this.$router.replace({ query: { q: this.query, t: this.type } });
      this.search(this.type, this.query);
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="card">

      <div class="card-header border-0">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="row g-2">
              <div class="col">
                <div class="position-relative mb-3">
                  <input ref="searchInput" type="text" class="form-control form-control-lg bg-light border-light" placeholder="Search here.." :value="this.data.search.query" v-on:keyup.enter="searchClick">
                </div>
              </div>
              <div class="col-auto">

                <div class="input-group">
                  <select class="form-select" v-model="type" v-on:change="changeType">
                    <option value="podcasts">Podcasts</option>
                    <option value="episodes">Episodes</option>
                    <option value="profiles">Profiles</option>
                    <option value="posts">Posts</option>
                  </select>
                  <button class="btn btn-primary btn-lg" type="button" v-on:click="searchClick">
                    <div class="btn-content"><i class="mdi mdi-magnify me-1"></i> Search </div>
                  </button>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <h5 class="fs-16 fw-semibold text-center mb-0">Showing results for: 
              <span class="text-primary fw-medium">{{ this.data.search.query }}</span>
            </h5>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div v-for="p in data.list" v-bind:key="p">
          <Result v-bind:data="p" v-bind:type="data.tab"></Result>
        </div>
        <Navigator v-if="false"></Navigator>
      </div>

    </div>
  </Layout>
</template>